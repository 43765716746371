import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DatatableComponent } from '@siemens/ngx-datatable';
import {
  DeleteConfirmationDialogResult,
  ElementDimensions,
  MenuItem,
  SiActionDialogService,
  SiModalService,
} from '@simpl/element-ng';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Device } from '../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { ProductType } from '../../../../shared/api-client/services/lcas-api-client/models/product-type.enum';
import { UpdateDevice } from '../../../../shared/api-client/services/lcas-api-client/models/update-device.model';
import { statusMapping } from '../../constants/device-status.constants';
import { EdgeConnectivityModalService } from '../../../../shared/edge-connectivity-modal.service';
import { WirelessDeviceType } from '../../../../shared/models/business/wireless-device-type.enum';
import {
  WIRELESS_ROOM_SENSORS,
  WirelessRoomSensorType,
} from '../../../../shared/models/business/wireless-room-sensor-type.enum';
import { EdgeConnectivityInfo } from '../../../../shared/models/edge-connectivity-info.model';
import { UpdateProjectStatusService } from '../../../../shared/services/update-project-status.service';
import {
  MaximumLengthForName,
  MinimumLengthForName,
} from '../../constants/reactive-form-input-constants';
import { SetupFormService } from '../../devices-setup-form/setup-form/services/setup-form.service';
import {
  LoadedStatus,
  LocationsState,
} from '../../location-store/models/location.mode';
import { LocationActions } from '../../location-store/store/location.actions';
import {
  selectBuildingProductLoadedStatus,
  selectProducts,
} from '../../location-store/store/location.reducers';
import { DeviceActionService } from '../../services/device-action.service';
import { FormAbstractionService } from '../../services/form-abstraction.service';
import { DeviceDetailsRow } from './models/device-details-row.model';
import { DeviceRow } from './models/device-row.model';
import { DevicesTableConfig } from './models/devices-table-config.model';
import { MD_BREAKPOINT_SIZE_PIX } from '../../constants/breakpoints.constants';

@Component({
  selector: 'app-devices-details',
  templateUrl: './devices-details.component.html',
  styleUrls: ['./devices-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table') table?: DatatableComponent;

  @ViewChild('moveDeviceModal') moveDeviceModalRef?: TemplateRef<Element>;

  @ViewChild('forceDeleteModalTemplate', { static: true })
  forceDeleteModalTemplate?: TemplateRef<Element>;

  @Input() selectedLocation?: Location;

  @Input() devicesTableConfig: DevicesTableConfig | undefined;

  @Input() devicesTableData: DeviceRow[] | undefined;

  @Input() edgeConnectivityInfo: EdgeConnectivityInfo | undefined;

  @Output() detailsClick = new EventEmitter<DeviceDetailsRow>();

  @Output() assignClick = new EventEmitter<DeviceDetailsRow>();

  @Output() addDevicesClick = new EventEmitter<Location>();

  private reloadDevices$: BehaviorSubject<string | undefined> =
    new BehaviorSubject(
      this.selectedLocation?.id ? this.selectedLocation?.id : undefined,
    );

  private devicesTableData$: Subject<DeviceRow[] | undefined> = new Subject();

  rows$?: Observable<DeviceRow[] | undefined> = combineLatest([
    this.reloadDevices$,
    this.store.select((store) => ({
      floorsAndLocations: store.floorsAndLocations,
    })),
    this.devicesTableData$.pipe(startWith([])),
  ]).pipe(
    distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
    map(([locationId, { floorsAndLocations }, devicesTableData]) => {
      if (!locationId && floorsAndLocations.isLoading) {
        this.form = undefined;
        return undefined;
      }
      const devices = floorsAndLocations.locationsWithDevices[locationId!];

      if (!floorsAndLocations.locationsWithDevices[locationId!]) {
        return [];
      }
      this.createDevicesForm(
        floorsAndLocations.locationsWithDevices[locationId!],
      );

      return devices.map((device) => {
        const deviceTableRow = devicesTableData?.find(
          (row) => row.id === device.id,
        );

        return {
          ...device,
          ...deviceTableRow,
          ...this.getDeviceMenuItems(device),
        } as DeviceRow;
      });
    }),
  );

  form?: FormGroup;

  selectedDevice?: DeviceDetailsRow;

  isMobileResolution: boolean = false;

  readonly mediaBaseUrl = environment.mediaBaseUrl;

  readonly statusMapping = statusMapping;

  readonly edgeDeviceType = 'SysXController';

  private subscriptions: Subscription[] = [];

  private subscriptionFormChanged: Subscription[] = [];

  errorCodeTranslateKeyMap = new Map<string, string>([
    ['required', 'FORM.INPUT_VALIDATION.REQUIRED'],
    ['minlength', 'FORM.INPUT_VALIDATION.MINLENGTH_NAME_ERROR'],
    ['maxlength', 'FORM.INPUT_VALIDATION.MAXLENGTH_NAME_ERROR'],
  ]);

  controlNameTranslateKeyMap = new Map<string, string>([
    ['name', 'FORM.CONTROL.NAME'],
  ]);

  private readonly deviceHideEditButton: {
    [k in WirelessDeviceType]: boolean;
  } = {
    BrdRout: true,
    Rout: true,
    RadVlvActr: true,
    WirelessRoomSensor: true,
  };

  isLoadingDevices$: Observable<boolean> = this.store.select(
    (store) => store.floorsAndLocations.isLoading,
  );

  constructor(
    private siActionDialogService: SiActionDialogService,
    private siModalService: SiModalService,
    private deviceActionService: DeviceActionService,
    private formAbstractionService: FormAbstractionService<Device>,
    private setupFormService: SetupFormService,
    private store: Store<{ floorsAndLocations: LocationsState }>,
    private updateProjectStatusService: UpdateProjectStatusService,
    private edgeConnectivityModalService: EdgeConnectivityModalService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.deviceActionService.openMoveDeviceModal$.subscribe(() => {
        if (this.moveDeviceModalRef) {
          this.siModalService.show(this.moveDeviceModalRef, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: false,
            keyboard: false,
          });
        }
      }),
    );
    this.subscriptions.push(
      this.deviceActionService.openForceDeleteDeviceModal$.subscribe((open) => {
        if (open && this.forceDeleteModalTemplate) {
          this.invokeDeleteForceModal();
        }
      }),
    );
    this.subscriptions.push(
      combineLatest([
        this.store.select(selectProducts),
        this.store.select(selectBuildingProductLoadedStatus),
      ])
        .pipe(
          distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
          filter(([, loadedStatus]) => loadedStatus === LoadedStatus.LOADED),
          switchMap(([products]) =>
            this.updateProjectStatusService.updateProjectStatus(
              false,
              products,
            ),
          ),
        )
        .subscribe(),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation?.currentValue) {
      this.store.dispatch(
        LocationActions.getLocationWithDevices({
          locationId: changes.selectedLocation.currentValue.id,
        }),
      );
      this.notifyReloadDevices(changes.selectedLocation.currentValue.id);
    }

    if (this.devicesTableData) {
      this.devicesTableData$.next(this.devicesTableData);
      this.edgeConnectivityModalService.checkEdgeConnection(
        this.edgeConnectivityInfo,
      );
    }
  }

  recalculateTable(event: ElementDimensions) {
    this.isMobileResolution = event.width < MD_BREAKPOINT_SIZE_PIX;
    this.table?.recalculate();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptionFormChanged.forEach((subscription) =>
      subscription.unsubscribe(),
    );
  }

  onInputKeyPress(e: KeyboardEvent, id: string): void {
    if (e.key.toLowerCase() === 'enter') {
      this.form?.controls[id].setValue((e.target as HTMLInputElement).value);
    }
  }

  openDetailsCardOnTap(row: DeviceDetailsRow) {
    if (this.isMobileResolution && !this.isEdgeDevice(row)) {
      this.detailsClick.emit(row);
    }
  }

  onAddDevicesClick(): void {
    this.addDevicesClick.emit(this.selectedLocation);
  }

  isEdgeDevice(device: DeviceDetailsRow): boolean {
    return device.type === this.edgeDeviceType;
  }

  private createDevicesForm(devices: Device[]) {
    this.form = this.formAbstractionService.createForm(devices, [
      Validators.required,
      Validators.minLength(MinimumLengthForName),
      Validators.maxLength(MaximumLengthForName),
    ]);

    if (this.subscriptionFormChanged.length) {
      this.subscriptionFormChanged.forEach((subscription) =>
        subscription.unsubscribe(),
      );
      this.subscriptionFormChanged = [];
    }
    this.subscriptionFormChanged.push(
      this.formAbstractionService
        .onControlChange(this.form)
        .subscribe(({ id, value }) =>
          this.onDeviceNameChange({
            id,
            name: value,
            eTag: devices.find((device) => device.id === id)?.eTag ?? 0,
          }),
        ),
    );
  }

  private isEdgeDeviceCommissioned(device: DeviceDetailsRow): boolean {
    return (
      device.type === this.edgeDeviceType &&
      device.setupStatus === 'COMMISSIONED'
    );
  }

  private confirmDeleteDevice(device: DeviceDetailsRow): void {
    this.selectedDevice = device;
    this.siActionDialogService
      .showDeleteConfirmationDialog(
        'COMMISSIONING.DEVICE_TABLE.DELETE_DEVICE.DESCRIPTION',
        'COMMISSIONING.DEVICE_TABLE.DELETE_DEVICE.TITLE',
        'GLOBALS.BUTTON.DELETE',
        'GLOBALS.BUTTON.CANCEL',
      )
      .pipe(
        first(),
        tap((result) => {
          if (result === DeleteConfirmationDialogResult.Delete) {
            this.store.dispatch(
              LocationActions.initDeleteDevice({
                locationId: this.selectedLocation?.id
                  ? this.selectedLocation?.id
                  : '',
                device,
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  initForceDelete() {
    if (this.selectedLocation && this.selectedDevice) {
      this.store.dispatch(
        LocationActions.initDeleteDevice({
          locationId: this.selectedLocation?.id,
          device: this.selectedDevice,
          force: true,
        }),
      );
    }
  }

  private invokeDeleteForceModal(): void {
    this.siModalService.show(this.forceDeleteModalTemplate!, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }

  private moveDeviceAction(deviceRow: DeviceDetailsRow): void {
    this.selectedDevice = deviceRow;
    this.deviceActionService.openMoveDeviceModal$.next(true);
  }

  private getDeviceMenuItems(row: DeviceDetailsRow): {
    primaryActions: MenuItem[];
    secondaryActions: MenuItem[];
  } {
    return {
      primaryActions: [
        ...(!this.isEdgeDevice(row)
          ? [
              {
                title: 'GLOBALS.BUTTON.DETAILS',
                icon: 'element-settings',
                action: (): void => {
                  console.log('details');
                },
              },
            ]
          : []),
      ],
      secondaryActions: [
        ...(this.devicesTableConfig?.showMoveActionButton
          ? [
              {
                title: 'GLOBALS.BUTTON.MOVE_TO',
                icon: 'element-move',
                action: (device: DeviceDetailsRow): void => {
                  this.moveDeviceAction(device);
                },
              },
            ]
          : []),
        ...(!this.isEdgeDevice(row)
          ? [
              ...(row.config &&
              (!(
                this.getWirelessDeviceType(row.type) in
                this.deviceHideEditButton
              ) ||
                !this.deviceHideEditButton[
                  <WirelessDeviceType>this.getWirelessDeviceType(row.type)
                ])
                ? [
                    {
                      title: 'GLOBALS.BUTTON.EDIT',
                      icon: 'element-edit',
                      action: (device: DeviceDetailsRow): void => {
                        this.getEditButtonAction(device);
                      },
                    },
                  ]
                : []),
              {
                title: 'GLOBALS.BUTTON.DELETE',
                icon: 'element-delete',
                action: (device: DeviceDetailsRow): void => {
                  this.confirmDeleteDevice(device);
                },
              },
            ]
          : [
              ...(!this.isEdgeDeviceCommissioned(row)
                ? [
                    {
                      title: 'GLOBALS.BUTTON.DELETE',
                      icon: 'element-delete',
                      action: (device: DeviceDetailsRow): void => {
                        this.confirmDeleteDevice(device);
                      },
                    },
                  ]
                : []),
            ]),
      ],
    };
  }

  private getEditButtonAction(device: DeviceDetailsRow): void {
    this.setupFormService
      .display({ id: device.id, config: device.config, eTag: device.eTag })
      .pipe(
        first(),
        tap((setupFormModel) => {
          if (setupFormModel) {
            this.store.dispatch(
              LocationActions.patchFormDevice({
                deviceId: device.id,
                setupFormModel,
                eTag: device.eTag,
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  private onDeviceNameChange(updateDevice: UpdateDevice): void {
    this.store.dispatch(
      LocationActions.initRenameDevice({
        device: updateDevice,
      }),
    );
  }

  private getWirelessDeviceType(type: ProductType): ProductType {
    return this.isWirelessRoomSensor(type) ? 'WirelessRoomSensor' : type;
  }

  private isWirelessRoomSensor(type: string): boolean {
    return WIRELESS_ROOM_SENSORS.includes(type as WirelessRoomSensorType);
  }

  private notifyReloadDevices(selectedLocation: string): void {
    this.reloadDevices$.next(selectedLocation);
  }
}
