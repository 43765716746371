import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, merge, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, scan } from 'rxjs/operators';

import { PointApiClientService } from '../../../../shared/api-client/services/point-api-client/point-api-client.service';
import { EdgeDeviceState } from '../../../../shared/devices-commissioning/models/edge-device-state.model';
import { Uuid, UuidService } from '../../../../shared/providers/uuid.provider';
import { DeviceDetailsRow } from '../../generic-components/devices-details/models/device-details-row.model';
import { DetailCardProperty } from '../models/detail-card-property.model';
import { DetailPropertiesPollingService } from './detail-properties-polling.service';

@Injectable()
export class DetailsPollingService {
  private readonly pollingInterval = 5000;

  constructor(
    @Inject(UuidService) private uuidService: Uuid,
    private store: Store<{ edgeDeviceState: EdgeDeviceState }>,
    private pollingService: DetailPropertiesPollingService,
    private pointApiClientService: PointApiClientService,
  ) {}

  getPropertiesWithRealTimeData(
    device: DeviceDetailsRow,
  ): Observable<DetailCardProperty[]> {
    return this.store
      .select((state) => state.edgeDeviceState.edgeDevice)
      .pipe(
        first(),
        map((edgeDevice) => {
          if (
            device.instance?.properties &&
            edgeDevice?.instance?.edgeConfig?.appId
          ) {
            return edgeDevice.instance?.edgeConfig.appId;
          }
          throw new Error('Cannot create property ids for device.');
        }),
        map((appId) =>
          Object.values(device.instance!.properties!).map((property) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: this.uuidService.v5(property._id, appId),
            data: property,
            isEdgeDeviceConnected: true,
          })),
        ),
        mergeMap((properties) =>
          this.pollingService
            .pollingOperator(this.pollingInterval)
            .pipe(
              mergeMap(() =>
                merge(
                  from(properties).pipe(
                    filter((property) =>
                      Object.prototype.hasOwnProperty.call(
                        property.data,
                        'lcas:cloudPush',
                      ),
                    ),
                    mergeMap((property) =>
                      this.pointApiClientService.getPoint(property.id).pipe(
                        map((data) => ({
                          ...property,
                          value: data.lastValue?.value,
                        })),
                        catchError(() => of(property)),
                      ),
                    ),
                  ),
                ),
              ),
            )
            .pipe(
              scan(
                (allProperties: DetailCardProperty[], polledProperty) =>
                  allProperties.map((prop) =>
                    prop.id === polledProperty.id ? polledProperty : prop,
                  ),
                properties,
              ),
            ),
        ),
      );
  }

  stopPolling(): void {
    this.pollingService.pause();
  }

  getTemplate(device: DeviceDetailsRow): Observable<DetailCardProperty[]> {
    return of(
      Object.values(device.instance!.properties!).map((property) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: property._id,
        data: property,
        isEdgeDeviceConnected: false,
      })),
    );
  }
}
