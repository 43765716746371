import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Feature } from '../../../../shared/api-client/services/device-api-client/models/feature.model';
import { DeviceApiClientService } from '../../../../shared/api-client/services/device-api-client/device-api-client.service';
import { DeviceStatus } from '../../../../shared/models/business/device-status.enum';
import { PlannedDevice } from '../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { PollingService } from './polling.service';
import { EdgeConnectivityInfo } from '../../../../shared/models/edge-connectivity-info.model';

@Injectable()
export class EdgeConnectivityService {
  constructor(
    private deviceApiClientService: DeviceApiClientService,
    private pollingService: PollingService,
  ) {}

  appendOnlineState(edgeDevice: PlannedDevice): Observable<PlannedDevice> {
    return edgeDevice.instance?.edgeConfig?.deviceId
      ? this.deviceApiClientService
          .getDeviceFeatures(edgeDevice.instance?.edgeConfig?.deviceId)
          .pipe(
            map((deviceFeatures: Feature[]) => ({
              ...edgeDevice,
              deviceStatus: (this.getOnlineState(deviceFeatures)
                ? 'connected'
                : 'not-connected') as DeviceStatus,
            })),
            catchError(() =>
              of({
                ...edgeDevice,
                deviceStatus: 'not-connected' as DeviceStatus,
              }),
            ),
          )
      : of(edgeDevice);
  }

  public pollEdgeConnectivityStatus(
    edgeDevice: PlannedDevice,
  ): Observable<boolean> {
    return this.pollingService.startPolling(
      this.getEdgeConnectivityStatus(edgeDevice),
      5000,
    );
  }

  public stopPollingEdgeConnectivityStatus(): void {
    this.pollingService.stopPolling();
  }

  public getEdgeConnectivityStatus(
    edgeDevice: PlannedDevice,
  ): Observable<boolean> {
    return edgeDevice.instance?.edgeConfig?.deviceId
      ? this.deviceApiClientService
          .getDeviceFeatures(edgeDevice.instance?.edgeConfig?.deviceId)
          .pipe(
            map((deviceFeatures: Feature[]) =>
              this.getOnlineState(deviceFeatures),
            ),
            catchError(() => of(false)),
          )
      : of(false);
  }

  public shouldChangeEdgeConnectivityInfo(
    currentIsEdgeConnected: boolean,
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return (
      this.firstTimePollingEdgeState(edgeConnectivityInfo) ||
      this.edgeChangedState(currentIsEdgeConnected, edgeConnectivityInfo)
    );
  }

  private getOnlineState(features: Feature[]): boolean {
    return !!features.find((feature) => feature.type === 'connectivity')
      ?.properties.connected;
  }

  private firstTimePollingEdgeState(
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return edgeConnectivityInfo.fetchedConnectivity === false;
  }

  private edgeChangedState(
    currentIsEdgeConnected: boolean,
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return currentIsEdgeConnected !== edgeConnectivityInfo.connected;
  }
}
