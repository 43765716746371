import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { Attributes } from './models/attributes.model';
import { Command } from './models/command.model';
import { Feature } from './models/feature.model';

@Injectable({ providedIn: ApiClientModule })
export class DeviceApiClientService {
  constructor(
    private httpClient: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  claimDevice(activationKey: string): Observable<{ id: string }> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<{ id: string }>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/organizations/${store.project?.id}/devices`,
        { activationKey },
      ),
    );
  }

  getDeviceFeatures(id: string): Observable<Feature[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Feature[]>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/organizations/${store.project?.id}/devices/${id}/features`,
      ),
    );
  }

  setAttributes({ edgeId, ...attributes }: Attributes): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.put<Attributes>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/organizations/${store.project?.id}/devices/${edgeId}/attributes`,
        attributes,
      ),
    );
  }

  setEdgeTimezone(featureId: string): Observable<Command> {
    return this.apiHelperService.callAndAddStore((store) => {
      const commandBody = {
        type: 'device-info.set-timezone',
        parameters: {
          timezone: store.building?.attributes.timeZone,
        },
      };

      return this.httpClient.post<Command>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/organizations/${store.project?.id}/features/${featureId}/commands`,
        commandBody,
      );
    });
  }

  getCommand(commandId: string): Observable<Command> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Command>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/organizations/${store.project?.id}/commands/${commandId}`,
      ),
    );
  }
}
