import { Injectable } from '@angular/core';

import { ProductType } from '../api-client/services/lcas-api-client/models/product-type.enum';
import { DeviceCategoryType } from '../models/business/device-category-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DeviceCategoryService {
  public readonly categoryToTypesMapping: {
    [k in DeviceCategoryType]: ProductType[] | undefined;
  } = {
    MtrEl: ['MtrElMsrdTot', 'MtrElCalcTot'],
    MtrHC: ['MtrH', 'MtrHC', 'MtrC'],
    PrimaryGenericRelay: ['PrimHSwiRDF', 'PrimCSwiRDF', 'CDstrSwi', 'HDstrSwi'],
    SabianaFanCoil: ['SabianaMB2p', 'SabianaMB4p'],
    WirelessRoomSensor: ['RUnit', 'TRSen', 'TRHuSen', 'TRHuAQualSen'],
    Unclassified: undefined,
  };

  getDeviceCategoryByType(deviceType: ProductType): DeviceCategoryType {
    return (
      (Object.keys(this.categoryToTypesMapping).find((category) =>
        this.categoryToTypesMapping[category as DeviceCategoryType]?.includes(
          deviceType,
        ),
      ) as DeviceCategoryType) || undefined
    );
  }
}
