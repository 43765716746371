import { HttpBackend, HttpClient } from '@angular/common/http';
import { deepmerge } from 'deepmerge-ts';
import {
  ITranslationResource,
  MultiTranslateHttpLoader,
} from 'ngx-translate-multi-http-loader';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class CustomMultiTranslateHttpLoader extends MultiTranslateHttpLoader {
  MEDIA_TRANSLATIONS_PATH = '/media/translations';

  LOCAL_TRANSLATIONS_PATH = './assets/local-translations';

  LOCAL_TRANSLATIONS_I18N_PATH = './assets/i18n';

  BT_UNITS_SYMBOLS_PREFIX = 'bt_units.symbols';

  HORIZON_UI_TRANSLATIONS_I18N_PATH: string = './assets/i18n/horizon-ui-ng';

  constructor(
    private handler: HttpBackend,
    private resources: ITranslationResource[],
  ) {
    super(handler, resources);
  }

  getTranslation(lang: string): Observable<any> {
    const translationResources = this.resources.filter(
      (resource: ITranslationResource) =>
        lang !== 'template' || resource.prefix.includes('assets'),
    );

    const requests = this.getRequests(translationResources, lang);
    const templateRequests = this.getRequests(translationResources, 'template');

    return forkJoin([
      ...templateRequests,
      ...requests,
      this.getUnitsRequest(translationResources),
    ] as Observable<any>[]).pipe(map((response) => deepmerge(...response)));
  }

  performRequest(path: string, optional = false) {
    return new HttpClient(this.handler).get(path).pipe(
      catchError((res) => {
        if (!optional) {
          console.group();
          console.error(
            'Something went wrong for the following translation file:',
            path,
          );
          console.error(res.message);
          console.groupEnd();
        }
        return of({});
      }),
    );
  }

  private getRequests(
    translationResources: ITranslationResource[],
    lang: string,
  ): Observable<object>[] {
    return translationResources.map((resource: ITranslationResource) => {
      let path: string;
      const isHorizonUITranslation: boolean = resource.prefix.includes(
        this.HORIZON_UI_TRANSLATIONS_I18N_PATH,
      );
      const isCoreTranslation: boolean =
        resource.prefix.includes('./assets/') && !isHorizonUITranslation;

      if (resource.prefix === this.BT_UNITS_SYMBOLS_PREFIX) {
        return of({});
      }

      const rootPath = isHorizonUITranslation
        ? resource.prefix
        : this.getRootPath(isCoreTranslation);

      if (isCoreTranslation || isHorizonUITranslation) {
        path = `${rootPath}/${lang}`;
      } else {
        path = `${rootPath}/${resource.prefix}.${lang}`;
      }

      path += `${resource.suffix ? resource.suffix : '.json'}`;

      return this.performRequest(path, resource.optional);
    });
  }

  private getUnitsRequest(
    translationResources: ITranslationResource[],
  ): Observable<object> {
    let path: string;
    const rootPath = this.getRootPath();

    const resource = translationResources.find(
      (r) => r.prefix === this.BT_UNITS_SYMBOLS_PREFIX,
    );
    if (resource === undefined) {
      return of({});
    }

    path = `${rootPath}/${resource.prefix}`;
    path += `${resource.suffix ? resource.suffix : '.json'}`;

    return this.performRequest(path, resource.optional);
  }

  private getRootPath(isCoreTranslation = false): string {
    let rootPath: string;

    if (window.location.hostname === 'localhost') {
      if (isCoreTranslation) {
        rootPath = this.LOCAL_TRANSLATIONS_I18N_PATH;
      } else {
        rootPath = this.LOCAL_TRANSLATIONS_PATH;
      }
    } else {
      rootPath = this.MEDIA_TRANSLATIONS_PATH;
    }

    return rootPath;
  }
}
