import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SiToastNotificationService } from '@simpl/element-ng';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { EdgeConnectivityService } from '../../sidemenu/commissioning/devices-installation/services/edge-connectivity.service';
import { FunctionDelete } from '../../sidemenu/commissioning/disciplines-and-functions/functions/functions-table/models/function-delete.model';
import { Device } from '../api-client/services/lcas-api-client/models/device.model';
import { FunctionalBlock } from '../api-client/services/lcas-api-client/models/functional-block.model';
import { ProductType } from '../api-client/services/lcas-api-client/models/product-type.enum';
import { Product } from '../api-client/services/lcas-api-client/models/product.model';
import { DevicesTableRow } from '../devices-commissioning/devices-table/models/devices-table-row.model';
import { EdgeDeviceState } from '../devices-commissioning/models/edge-device-state.model';
import { BorderRouterDeletionService } from './border-router-deletion.service';

@Injectable()
export class DeletionPermissionService {
  constructor(
    private store: Store<{
      edgeDeviceState: EdgeDeviceState;
    }>,
    private edgeConnectivityService: EdgeConnectivityService,
    private siToastNotificationService: SiToastNotificationService,
    private borderRouterDeletionService: BorderRouterDeletionService,
  ) {}

  canDeleteDevice(
    device: Device | DevicesTableRow,
    products?: Product[],
  ): Observable<boolean> {
    return this.borderRouterDeletionService.isBorderRouterAndCommissioned(
      device,
    )
      ? combineLatest([
          this.canDelete(device.setupStatus),
          this.borderRouterDeletionService.canDeleteBorderRouter(device),
        ]).pipe(map((booleans) => booleans.every((b) => !!b)))
      : combineLatest([
          this.canDeleteEdgeDevice(device.type, products),
          this.canDelete(device.setupStatus),
        ]).pipe(map((booleans) => booleans.every((b) => !!b)));
  }

  canDeleteFunction(
    functionalBlock: FunctionalBlock | FunctionDelete,
  ): Observable<boolean> {
    return this.canDelete(functionalBlock.setupStatus);
  }

  private canDelete(setupStatus: string): Observable<boolean> {
    return setupStatus === 'COMMISSIONED' ||
      setupStatus === 'COMMISSIONED_READY_FOR_UPDATE'
      ? this.store
          .select((state) => state.edgeDeviceState.edgeDevice)
          .pipe(
            first(),
            switchMap((edgeDevice) => {
              return edgeDevice
                ? this.edgeConnectivityService
                    .getEdgeConnectivityStatus(edgeDevice)
                    .pipe(
                      tap((edgeDeviceConnected) => {
                        if (!edgeDeviceConnected) {
                          this.showNotificationIfEdgeIsOffline();
                        }
                      }),
                    )
                : of(false);
            }),
          )
      : of(true);
  }

  private canDeleteEdgeDevice(
    type: ProductType,
    products: Product[] | undefined,
  ): Observable<boolean> {
    if (type === 'SysXController' && products) {
      return this.isEdgeDeviceTheOnlyProduct(products)
        ? of(true)
        : (this.showNotificationIfDeletingEdge(), of(false));
    }

    return of(true);
  }

  private isEdgeDeviceTheOnlyProduct(products: Product[]): boolean {
    const productsWithQuantity = products.filter(
      (product) => product.quantity > 0,
    );

    if (productsWithQuantity.length > 1) {
      return false;
    }

    return productsWithQuantity[0].descriptor.dataType === 'SysXController';
  }

  private showNotificationIfEdgeIsOffline(): void {
    this.siToastNotificationService.queueToastNotification(
      'warning',
      'COMMISSIONING.TOAST.MESSAGE_EDGE_OFFLINE',
      '',
    );
  }

  private showNotificationIfDeletingEdge(): void {
    this.siToastNotificationService.queueToastNotification(
      'warning',
      'BUILDING.DELETE_EDGE_DEVICE.TITLE',
      'BUILDING.DELETE_EDGE_DEVICE.MESSAGE',
    );
  }
}
