import { Injectable } from '@angular/core';

import { CommissioningRoute } from '../models/business/commissioning-route.enum';
import { MeterCategory } from '../models/business/meter-category.enum';
import { VirtualDeviceType } from '../models/business/virtual-device-type.enum';
import { WiredDeviceType } from '../models/business/wired-device-type.enum';
import { WirelessDeviceType } from '../models/business/wireless-device-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CommissioningRouteService {
  public readonly typeToRouteMapping: {
    [k in
      | WirelessDeviceType
      | MeterCategory
      | WiredDeviceType
      | VirtualDeviceType]: CommissioningRoute;
  } = {
    BrdRout: 'border-router-installation',
    Rout: 'router-installation',
    RadVlvActr: 'actuator-installation',
    TstatRdf: 'thermostat-installation',
    VirtualDevice: 'virtual-device-installation',
    PrimaryGenericRelay: 'primary-relay-installation',
    WirelessRoomSensor: 'sensor-installation',
    SabianaFanCoil: 'sabiana-fancoil-installation',
    MtrHC: 'scan',
    MtrEl: 'assign',
  };

  getTypeByRoute(
    route: CommissioningRoute,
  ): WirelessDeviceType | MeterCategory | WiredDeviceType {
    return Object.keys(this.typeToRouteMapping).find(
      (type) =>
        this.typeToRouteMapping[type as WirelessDeviceType | MeterCategory] ===
        route,
    ) as WirelessDeviceType | MeterCategory;
  }
}
