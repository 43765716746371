import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { EMPTY, forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, scan } from 'rxjs/operators';

import { DeviceStatus } from '../../../../shared/models/business/device-status.enum';
import { PlannedDevice } from '../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { Point } from '../../../../shared/api-client/services/point-api-client/models/point.model';
import { PointApiClientService } from '../../../../shared/api-client/services/point-api-client/point-api-client.service';
import { deviceStatusConfig } from '../../../../shared/constants/device-status-config.constants';
import { QualityDeviceStatusMappingService } from '../../../../shared/devices-commissioning/services/quality-device-status-mapping.service';
import {
  ConfigProperty,
  PolledConfigProperty,
  PolledProperties,
} from '../models/properties-polling.model';
import { EdgeConnectivityService } from './edge-connectivity.service';
import { PollingService } from './polling.service';

@Injectable({ providedIn: 'root' })
export class PlannedDevicesPollingService {
  private readonly pollingInterval = 5000;

  constructor(
    private pollingService: PollingService,
    private edgeConnectivityService: EdgeConnectivityService,
    private pointApiClientService: PointApiClientService,
    private qualityDeviceStatusMappingService: QualityDeviceStatusMappingService,
  ) {}

  getDevicesWithRealTimeData(
    devices: PlannedDevice[],
  ): Observable<PlannedDevice[]> {
    const edgeDevice = this.getEdgeDevice(devices);
    const commissionedDevices = this.getCommissionedDevices(devices);
    const checkIfDevicesExists = edgeDevice ?? commissionedDevices.length;

    const edgeAppendOnlineState = edgeDevice
      ? this.edgeConnectivityService.appendOnlineState(edgeDevice)
      : EMPTY;

    return merge(
      of(devices),
      checkIfDevicesExists
        ? this.pollingService
            .startPolling<PlannedDevice>(
              merge(
                edgeAppendOnlineState,
                this.getPropertiesFromDevices(commissionedDevices),
              ),
              this.pollingInterval,
            )
            .pipe(
              scan(
                (allDevices, polledDevice) =>
                  allDevices.map((d) =>
                    d.id === polledDevice.id ? polledDevice : d,
                  ),
                devices,
              ),
            )
        : EMPTY,
    );
  }

  private getPropertiesFromDevices(
    commissionedDevices: PlannedDevice[],
  ): Observable<PlannedDevice> {
    return commissionedDevices.length
      ? from(commissionedDevices).pipe(
          mergeMap((device) => {
            const mainPropertyId = device.instance?.mainPropertyId;
            const filteredProperties = this.getFilteredProperties(device);

            return this.fetchProperties(
              mainPropertyId,
              filteredProperties,
            ).pipe(
              map(
                (data) =>
                  ({
                    ...device,
                    value: data.mainProperty?.lastValue?.value ?? device.value,
                    deviceStatus:
                      data.mainProperty &&
                      !Number.isNaN(
                        data.mainProperty?.lastValue?.attributes
                          ?.qualityOfValue,
                      )
                        ? this.qualityDeviceStatusMappingService.getDeviceStatus(
                            data.mainProperty?.lastValue?.attributes
                              ?.qualityOfValue,
                          )
                        : device.deviceStatus,
                    secondaryDeviceStatus: this.getSecondaryDeviceStatus(
                      data.wirelessProperties,
                    ),
                  }) as PlannedDevice,
              ),
            );
          }),
        )
      : EMPTY;
  }

  stopPolling(): void {
    this.pollingService.stopPolling();
  }

  private getEdgeDevice(devices: PlannedDevice[]): PlannedDevice | undefined {
    return devices.find((device) => device.type === 'SysXController');
  }

  private getCommissionedDevices(devices: PlannedDevice[]): PlannedDevice[] {
    return devices.filter(
      (device) =>
        device.type !== 'SysXController' &&
        device.setupStatus === 'COMMISSIONED',
    );
  }

  private getSecondaryDeviceStatus(
    polledProperties: PolledConfigProperty[],
  ): DeviceStatus | undefined {
    const prioritizedConfig = sortBy(deviceStatusConfig, 'priority');

    // eslint-disable-next-line no-restricted-syntax
    for (const config of prioritizedConfig) {
      const polledProperty = polledProperties.find(
        (property) => property.dataType === config.dataType,
      );
      const value = polledProperty?.pointData?.lastValue?.value;
      const secondaryDeviceStatus =
        value &&
        this.qualityDeviceStatusMappingService.getSecondaryDeviceStatus(
          config.dataType,
          value,
        );
      if (secondaryDeviceStatus) {
        return secondaryDeviceStatus;
      }
    }

    return undefined;
  }

  private getFilteredProperties(device: PlannedDevice): ConfigProperty[] {
    const filteredProperties: ConfigProperty[] = [];
    deviceStatusConfig.forEach((config) => {
      if (
        Object.keys(device.instance?.properties ?? {}).includes(
          config.dataType,
        ) &&
        device.instance?.properties[config.dataType].computedId
      ) {
        filteredProperties.push({
          dataType: config.dataType,
          id: device.instance.properties[config.dataType].computedId!,
        });
      }
    });

    return filteredProperties;
  }

  private fetchProperties(
    mainPropertyId: string | undefined,
    filteredProperties: ConfigProperty[],
  ): Observable<PolledProperties> {
    return forkJoin({
      mainProperty: mainPropertyId
        ? this.fetchProperty(mainPropertyId)
        : of(undefined),
      wirelessProperties: filteredProperties.length
        ? forkJoin(
            filteredProperties.map((property) =>
              this.fetchProperty(property.id).pipe(
                map((point) => ({
                  ...property,
                  pointData: point,
                })),
              ),
            ),
          )
        : of([]),
    });
  }

  private fetchProperty(propertyId: string): Observable<Point | undefined> {
    return this.pointApiClientService
      .getPoint(propertyId)
      .pipe(catchError(() => of(undefined)));
  }
}
